<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_undecided_mail')}}</b-breadcrumb-item>
      </div>
    </div>

    <b-card no-body v-if="$can('edit', 'mail.tab_details')">
      <b-card-header class="pb-50">
        <h5>{{$t('label_undecided_mail')}}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mb-2 d-flex align-items-center"
          >

            <b-button variant="outline-primary" v-if="$can('edit', PREFIX+'.tab_details')" :to="{ name: 'mail_add' }" class="mr-1 ">
              <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
              />
              <span>{{$t('label_add_mail')}}</span>
            </b-button>
            <b-button variant="outline-primary" @click="$bvModal.show(PARTICIPANT_PREFIX+'-modal')" v-if="$can('edit', PREFIX+'.tab_details')" class="mr-1" >
              <feather-icon
                      icon="UserPlusIcon"
                      class="mr-50"
              />
              <span>{{$t('label_add_to_recipient_database')}}</span>
            </b-button>

          </b-col>
          <b-col cols="12">
            <b-row class="mt-2">
              <b-col cols="12" md="3" class="date-range-col">
                <label class="d-block">{{$t('label_deadline_reply')}}</label>
                <div class="date-range-filter mb-2">
                  <custom-date-picker
                          class="date--picker no-label"
                          :locale="$store.state.appConfig.locale"
                          :placeholder="$t('label_DD-MM-YYYY')"
                          :value="filterData.deadline_reply_at_from"
                          @input="(val) => {filterData.deadline_reply_at_from = val}"
                  >
                    <template #clear-btn="{ vm }">
                      <feather-icon icon="XIcon" size="14"/>
                    </template>
                  </custom-date-picker>

                  <div class="date-separator"/>
                  <custom-date-picker
                          class="date--picker no-label"
                          :locale="$store.state.appConfig.locale"
                          :placeholder="$t('label_DD-MM-YYYY')"

                          :value="filterData.deadline_reply_at_to"
                          @input="(val) => {filterData.deadline_reply_at_to = val}"
                  >
                    <template #clear-btn="{ vm }">
                      <feather-icon icon="XIcon" size="14"/>
                    </template>
                  </custom-date-picker>

                </div>
              </b-col>
              <b-col
                      cols="12"
                      md="3"
                      class=" mb-1 date-range-col"
              >
                <label>{{ $t('label_document_source') }}</label>

                <infinity-scroll
                        v-model="filterData.user_id"
                        selected-prop="id"
                        url="/user"
                        :multiple="false"
                        :placeholder="$t('label_select')"
                >
                  <template #label="{item}">{{item.name}}</template>

                </infinity-scroll>

              </b-col>
              <b-col cols="12" md="3" class="mb-1">
                <label>{{$t('label_responsible_person')}}</label>
                <infinity-scroll
                        selected-prop="id"
                        :multiple="false"
                        url="/user"
                        :placeholder="$t('label_select')"
                        v-model="filterData.responsible_user_id"
                >
                  <template #label="{item}">{{ item.name }}</template>

                </infinity-scroll>

              </b-col>
              <b-col
                      cols="12"
                      md="3"
                      class="mb-md-0 mb-2 filter-checkbox"
              >

                <!--<label>{{ $t('label_marketing') }}</label>-->
                <b-form-checkbox
                        id="ref-link-filter"
                        value="scan"
                        unchecked-value="null"
                        v-model="filterData.sort_by_source"
                >
                  <label for="ref-link-filter">{{ $t('label_scanning_device') }}</label>
                </b-form-checkbox>


              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
                  cols="12"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

          >
            <!--<label>{{ $t('label_show') }}</label>-->
            <v-select
                    v-model="tableData.perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tableData.perPageOptions"
                    :clearable="false"
                    :searchable="false"
                    class="per-page-selector d-inline-block mx-50"
            >
              <template v-slot:no-options="option">
                {{$t('label_no_items')}}
              </template>
            </v-select>

          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="5"
                  class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

              <b-form-input
                      v-model="tableData.searchQuery"
                      type="search"
                      debounce="500"
                      class="d-inline-block mr-1 "
                      :placeholder="$t('label_search')+'...'"
              />


          </b-col>
        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

     <div class="px-2 table-container-wrap">
       <!--sticky-header-->
       <b-table
               ref="table"
               class="position-relative transited-table"
               :busy="isBusy"
               no-border-collapse
               :items="tableItems"
               responsive
               :fields="columnsDefs"
               primary-key="id"
               :sort-by.sync="tableData.sortBy"
               no-local-sorting
               :sort-desc.sync="tableData.sortDesc"
               show-empty
               :tbody-transition-props="{ name: 'flip-list'}"
               :empty-text="$t('label_no_table_data')"
               :tbody-tr-class="resolveRowClassVariant"
       >

         <template #head()="data">
           <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
         </template>
         <template #head(checkboxes)="data">
           <b-dropdown v-if="checkedIds.length > 0"
                       class="selected-items-dropdown"
                       variant="link"
                       no-caret
                       :right="$store.state.appConfig.isRTL"
           >
             <template #button-content>
               <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
             </template>

             <b-dropdown-item
                     @click="deleteSelectedItems()">
               <feather-icon icon="TrashIcon"/>
               <span class="align-middle ml-50">{{ $t('label_delete_selected') }}</span>
             </b-dropdown-item>

           </b-dropdown>

           <span v-else class="text-secondary">{{ $t(data.label).toUpperCase() }}</span>

         </template>
         <!-- Column: Status -->
         <template #cell(checkboxes)="data">

           <!--<label>{{ $t('label_recommended') }}</label>-->
           <b-form-checkbox
                   :value="data.item.id"
                   v-model="checkedIds"
           >

           </b-form-checkbox>

         </template>
         <template #cell(source)="data">

           {{data.item.document_source == 'default'? data.item.user.name : $t('label_scanning_device')}}
         </template>
         <!--<template #cell(display_name)="data">-->
           <!--{{data.item.documents && data.item.documents.length>0? data.item.documents[0].display_name : ''}}-->
         <!--</template>-->


         <template #cell(document)="data">

           <a v-b-tooltip.hover.top="getFileName(data.item.documents[0])" v-if="data.item.documents[0]" @click="previewFile($event,  'mail/download/', Object.assign(data.item.documents[0], {id:data.item.id}))" href="#"> <feather-icon icon="FileIcon"></feather-icon> </a>

         </template>

         <template #cell(description)="data">
            <div v-b-tooltip.hover.top="data.item.description" class="one-line-limit">{{data.item.description}}</div>
         </template>

         <template #cell(responsible_user)="data">
            <div  class="one-line-limit">{{data.item.responsible_user? data.item.responsible_user.name : '--'}}</div>
         </template>


         <!-- Column: Actions -->
         <template #cell(actions)="data">
           <b-dropdown
                   dropleft
                   boundary="window"
                   variant="link"
                   no-caret
                   @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                   @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                   :right="$store.state.appConfig.isRTL"
           >

             <template #button-content>
               <feather-icon
                       icon="MoreVerticalIcon"
                       size="16"
                       class="align-middle text-body"
               />
             </template>



             <b-dropdown-item disabled>
               <div class="divider">
                 <div class="divider-text">{{ $t('label_action') }}</div>
               </div>
             </b-dropdown-item>

             <b-dropdown-item :to="{name: 'mail_edit', params: { id: data.item.id }}">
               <feather-icon icon="EditIcon"/>
               <span class="align-middle ml-50">{{ $t('label_assign_document') }}</span>
             </b-dropdown-item>

             <b-dropdown-item @click="deleteItem(data.item.id)">
               <feather-icon icon="TrashIcon"/>
               <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
             </b-dropdown-item>


           </b-dropdown>
         </template>

       </b-table>
       <div class="mx-2 mb-2">
         <b-row>

           <b-col
                   cols="12"
                   sm="6"
                   class="d-flex align-items-center justify-content-center justify-content-sm-start"
           >
             <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
           </b-col>
           <!-- Pagination -->
           <b-col
                   cols="12"
                   sm="6"
                   class="d-flex align-items-center justify-content-center justify-content-sm-end"
           >

             <b-pagination
                     v-model="tableData.currentPage"
                     :total-rows="tableTotal"
                     :per-page="tableData.perPage"
                     first-number
                     last-number
                     class="mb-0 mt-1 mt-sm-0"
                     prev-class="prev-item"
                     next-class="next-item"
             >
               <template #prev-text>
                 <feather-icon
                         icon="ChevronLeftIcon"
                         size="18"
                 />
               </template>
               <template #next-text>
                 <feather-icon
                         icon="ChevronRightIcon"
                         size="18"
                 />
               </template>
             </b-pagination>

           </b-col>

         </b-row>
       </div>
     </div>

    </b-card>

    <process-member-modal ></process-member-modal>

  </div>
</template>

<script>
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, VBTooltip,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import processMemberModal from './../mail/modals/processMemberModal'
    import  infinityScroll from '@/views/components/infinityScroll'

    import {MAIL_PREFIX as PREFIX, PARTICIPANT_PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,

            vSelect,
            processMemberModal,
            infinityScroll
        },

        data() {
            return {
                PARTICIPANT_PREFIX,
                PREFIX,

                checkedIds: [],

                selectedColumns:[],

                columnsDefs: [

                    {label: 'label_#', key: 'checkboxes', sortable: false,tdClass: 'checkbox-cell', thClass: 'table-action-wrap'},
                    {label: 'label_date_of_creating', key: 'created_at', sortable: false,
                        formatter: function (v, k, item) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix')
                        }
                    },
                    {label: 'label_description', key: 'description', sortable: false },
                    {label: 'label_source', key: 'source', sortable: false},
                    {
                        label: 'label_deadline_reply', key: 'deadline_reply_at', sortable: false,
                        formatter: function (v, k, item) {
                            return v? formatDate(v, 'DD.MM.YYYY', 'unix') : '--';
                        }
                    },
                    {label: 'label_document', key: 'document'},
                    // {label: 'label_document_name', key: 'display_name'},
                    {label: 'label_responsible_person', key: 'responsible_user'},
                    {label: 'label_action', key: 'actions'},

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData: {
                    sort_by_source: null,
                    deadline_reply_at_from: null,
                    deadline_reply_at_to: null,
                    user_id: null,
                    responsible_user_id: null
                },
                // sourceOptions:[],

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        created() {

            this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el)=>{
                if((el.key == 'actions' || el.key == 'checkboxes') && !this.$can('edit', 'mail.tab_details')){
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            getPageData(){

                this.async('get', '/'+this.PREFIX+'/selects',{params:{mail_type:'undecided'}}, function(res){

                    let related_users = res.data.relUsers;
                    // this.sourceOptions =[{label:'label_scanning_device', value: 'scan'}];
                    // for(let i=0; i<related_users.length; i++){
                    //     sourceOptions.push({label:related_users[i].name, value:related_users[i].id});
                    // }
                    // this.sourceOptions = sourceOptions;
                });

            },

            changeDealStatus(module_id, status_alias) {
                this.async('post', '/'+this.PREFIX+'/change_status/' + module_id, {status_alias: status_alias}, function (resp) {
                    this.refreshDataTable();
                });

            },

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/'+this.PREFIX, {params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                        mail_type: 'undecided',

                        sort_by_source: this.filterData.sort_by_source,
                        deadline_reply_at_from: this.filterData.deadline_reply_at_from,
                        deadline_reply_at_to: this.filterData.deadline_reply_at_to,
                        responsible_user_id: this.filterData.responsible_user_id,
                        user_id: this.filterData.user_id,

                    }}, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/'+this.PREFIX+'/'+id, {}, function (resp) {
                        this.checkedIds = this.checkedIds.filter((it)=>it != id);
                        this.refreshDataTable();
                    });
                })
            },
            resolveRowClassVariant(item, type) {
                if (!item || type !== 'row') return
                if (this.checkedIds.includes(item.id)) return 'row-success';
            },
            deleteSelectedItems() {
                this.confirmDeleting((result) => {
                    this.async('post', '/'+this.PREFIX + '/removeSelectedMails', {selected_ids:this.checkedIds}, function (resp) {
                        this.refreshDataTable();
                        this.checkedIds = [];
                    });
                })

            },
            getFileName(file){
                return file.display_name + '.' + file.file_name.split('.').pop();
            }


        },

    }
</script>

